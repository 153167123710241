<template>
  <div class="payment-data-table" :class="{ orange: payment.orangeFlg }">
    <div class="header h-date">
      <div class="text" v-if="!payment.cancelFlg">会計日</div>
      <div class="text red" v-else>取消日</div>
    </div>
    <div class="header h-billed">
      <div class="text" v-if="!payment.cancelFlg">①請求額（￥）</div>
      <div class="text" v-else>取消額（③ - ②）</div>
    </div>
    <div class="header h-received">
      <div class="text">④入金額小計（⑤ + ⑥）</div>
    </div>
    <div class="header h-change">
      <div class="text" v-if="!payment.cancelFlg">お釣（￥）</div>
    </div>
    <div class="header h-unpaid">
      <div class="text" v-if="!payment.cancelFlg">未収金（￥）</div>
    </div>
    <div class="header h-prev">
      <div class="text">②前回未収金（￥）</div>
    </div>
    <div class="header h-burden">
      <div class="text">③診療費（￥）</div>
    </div>
    <div class="header h-cash">
      <div class="text">⑤現金（￥）</div>
    </div>
    <div class="header h-card">
      <div class="text">⑥カード決済（￥）</div>
    </div>
    <div class="cell date">
      <div class="text" v-if="!payment.cancelFlg">
        {{ payment.payDateTime }}
      </div>
      <div class="text date" v-else>{{ payment.cancelDateTime }}</div>
    </div>
    <div class="cell billed">
      <div class="text" v-if="!payment.cancelFlg">
        {{ formatNumber(payment.billedAmount) }}
      </div>
      <div class="text" v-else>
        {{ formatNumber(payment.cancelAmount) }}
      </div>
    </div>
    <div class="cell received">
      <div class="text">
        {{ formatNumber(payment.paymentReceived) }}
      </div>
    </div>
    <div class="cell change">
      <div class="text" v-if="!payment.cancelFlg">
        {{ formatNumber(payment.change) }}
      </div>
    </div>
    <div class="cell unpaid">
      <div class="text" v-if="!payment.cancelFlg">
        {{ formatNumber(payment.unpaidAmount) }}
      </div>
    </div>
    <div class="cell prev">
      <div class="text">
        {{ formatNumber(payment.previousUnpaidAmount) }}
      </div>
    </div>
    <div class="cell burden">
      <div class="text">
        {{ formatNumber(payment.burdenAmount) }}
      </div>
    </div>
    <div class="cell cash">
      <validation-provider
        v-if="inputFlg"
        v-slot="{ errors }"
        class="validation-provider"
        :rules="{ intRule: true }"
      >
        <base-text-box
          data-test="base-text-box cash"
          :disabled="textBoxDisabled"
          :fullWidthNumbersToHalfWidthNumbers="true"
          :maxlength="7"
          :styles="textBoxStyle"
          :value="inputPayment.cash"
          @blur="blur('cash')"
          @dblclick="autoInput('cash')"
          @input="inputNum($event, 'cash')"
        />
        <div v-if="errors[0]" class="error" data-test="error cash">
          {{ errors[0] }}
        </div>
      </validation-provider>
      <div v-else class="text">
        {{ formatNumber(payment.cash) }}
      </div>
    </div>
    <div class="cell card">
      <validation-provider
        v-if="inputFlg"
        v-slot="{ errors }"
        class="validation-provider"
        :rules="{
          intRule: true,
          lessThanOrEqualTo: { maxValue: payment.billedAmount, words: '請求額' }
        }"
      >
        <base-text-box
          data-test="base-text-box card"
          :disabled="textBoxDisabled"
          :fullWidthNumbersToHalfWidthNumbers="true"
          :maxlength="7"
          :styles="textBoxStyle"
          :value="inputPayment.card"
          @blur="blur('card')"
          @dblclick="autoInput('card')"
          @input="inputNum($event, 'card')"
        />
        <div v-if="errors[0]" class="error" data-test="error card">
          {{ errors[0] }}
        </div>
      </validation-provider>
      <div v-else class="text">
        {{ formatNumber(payment.card) }}
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'PaymentDataTable',

  components: {
    BaseTextBox,
    ValidationProvider
  },

  props: {
    inputFlg: { type: Boolean, default: false },
    inputPayment: { type: Object },
    needsMiddleCalculateOrEndMedicalPaymentUpdateFlg: {
      type: Boolean,
      default: false
    },
    needsInsuranceUpdate: { type: Boolean },
    payment: { type: Object, required: true }
  },

  data() {
    return {
      textBoxStyle: {
        borderColor: '#ef6c00',
        borderRadius: '2px',
        borderWidth: '2px',
        height: '34px',
        fontSize: '13px',
        textAlign: 'right',
        width: '100%'
      }
    }
  },

  computed: {
    textBoxDisabled() {
      return (
        this.needsMiddleCalculateOrEndMedicalPaymentUpdateFlg ||
        this.needsInsuranceUpdate
      )
    }
  },

  methods: {
    formatNumber(val) {
      return val === undefined ? '' : `￥${val.toLocaleString()}`
    },
    blur(inputName) {
      if (this.inputPayment[inputName] === '') this.inputPayment[inputName] = 0
    },
    autoInput(inputName) {
      if (inputName === 'cash') {
        this.inputPayment.cash = this.payment.billedAmount
        this.inputPayment.card = 0
      } else if (inputName === 'card') {
        this.inputPayment.card = this.payment.billedAmount
        this.inputPayment.cash = 0
      }
    },
    inputNum(value, field) {
      const isInt = /^([0-9]\d*)$/.test(value)
      const newValue = isInt ? Number(value) : value
      this.inputPayment[field] = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-data-table {
  min-width: 1380px;
  margin-top: 30px;
  font-size: 13px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 34px);
  grid-template-areas:
    'h-date h-billed h-billed h-received h-received h-change h-unpaid'
    'date   billed   billed   received   received   change   unpaid'
    'date   h-prev   h-burden  h-cash     h-card     .        .'
    'date   prev     burden    cash       card       .        .';
  box-sizing: border-box;
  border: 1px solid #{$light-grey};
  > .header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-right: 1px solid #{$light-grey};
    border-bottom: 1px solid #{$light-grey};
    background-color: #{$gray_fc};
    > .red {
      color: #de3d3d;
    }
  }
  > .cell {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-right: 1px solid #{$light-grey};
    border-bottom: 1px solid #{$light-grey};
    > .text {
      padding-right: 10px;
    }
    > .validation-provider {
      flex: 1;
      position: relative;
      > .error {
        position: absolute;
        z-index: 10;
        color: #{$tomato};
        font-size: 12px;
        text-align: left;
      }
    }
  }
  > .h-date {
    grid-area: h-date;
  }
  > .h-billed {
    grid-area: h-billed;
  }
  > .h-received {
    grid-area: h-received;
  }
  > .h-change {
    grid-area: h-change;
  }
  > .h-unpaid {
    grid-area: h-unpaid;
    border-right: 0;
  }
  > .h-prev {
    grid-area: h-prev;
  }
  > .h-burden {
    grid-area: h-burden;
  }
  > .h-cash {
    grid-area: h-cash;
  }
  > .h-card {
    grid-area: h-card;
  }
  > .date {
    grid-area: date;
    border-bottom: 0;
    justify-content: center;
    > .text {
      padding: 0;
    }
  }
  > .billed {
    grid-area: billed;
  }
  > .received {
    grid-area: received;
  }
  > .change {
    grid-area: change;
  }
  > .unpaid {
    grid-area: unpaid;
    border-right: 0;
  }
  > .prev {
    grid-area: prev;
    border-bottom: 0;
  }
  > .burden {
    grid-area: burden;
    border-bottom: 0;
  }
  > .cash {
    grid-area: cash;
    border-bottom: 0;
  }
  > .card {
    grid-area: card;
    border-bottom: 0;
  }
  &.orange {
    > .header {
      background-color: #{$very-light-shade-orange};
    }
  }
}
</style>
