<template>
  <div class="estimate-list-table">
    <div class="table-header">
      <div class="cell date">見積日</div>
      <div class="cell disease-name">診断名</div>
    </div>
    <div class="table-body">
      <div class="row" v-for="estimate in estimates" :key="estimate.id">
        <div class="upper">
          <div class="texts">
            <div class="date">{{ estimate.jpFormatDate }}</div>
            <div class="disease-name" :title="estimate.diseaseName">
              {{ estimate.diseaseName }}
            </div>
          </div>
          <slot name="buttons" :estimate="estimate"></slot>
        </div>
        <div class="under">
          <div class="item left">
            <data-table
              v-if="estimate.treatmentItems.length > 0"
              v-bind="treatmentItemData"
              :items="makeDisplayTreatmentItems(estimate.treatmentItems)"
            />
          </div>
          <div class="item right">
            <data-table
              v-bind="priceDetailData"
              :items="makeDisplayPriceDetails(estimate)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/parts/organisms/DataTable'
import { makePriceDetail } from '@/utils/price_calculation'

const treatmentItemStyles = [
  { width: '38%' },
  { width: '14%' },
  { width: '10%' },
  { width: '10%' },
  { width: '14%' },
  { width: '14%' }
]

export default {
  name: 'EstimateListTable',

  components: {
    DataTable
  },

  props: {
    estimates: { type: Array }
  },

  data() {
    return {
      treatmentItemData: {
        keys: [
          'name',
          'unitPrice',
          'amount',
          'discountRate',
          'discountPrice',
          'subtotal'
        ],
        headers: [
          '診療項目内容',
          '単価（￥）',
          '数量',
          '割引（%）',
          '値引（￥）',
          '小計（￥）'
        ],
        headerCellStyles: treatmentItemStyles,
        bodyCellStyles: treatmentItemStyles.map((v, i) => {
          return { ...v, textAlign: i === 0 ? 'center' : v.textAlign }
        }),
        bodyMaxHeight: '120px',
        scrollFlg: true
      },
      priceDetailData: {
        keys: [
          'discountRate',
          'discountPrice',
          'pledgeRate',
          'insurancePrice',
          'consumptionTax',
          'ownerBill'
        ],
        headers: [
          '全体割引（%）',
          '全体値引（￥）',
          '保険割引率（%）',
          '保険負担額（￥）',
          '消費税（￥）',
          '飼主請求額（￥）'
        ]
      }
    }
  },

  methods: {
    makeDisplayTreatmentItems(treatmentItems) {
      return treatmentItems.map(v => {
        return {
          ...v,
          unitPrice: `￥${v.unitPrice.toLocaleString()}`,
          discountRate: `${v.discountRate.toLocaleString()} %`,
          discountPrice: `￥${v.discountPrice.toLocaleString()}`,
          subtotal: `￥${v.subtotal.toLocaleString()}`
        }
      })
    },
    makeDisplayPriceDetails(estimate) {
      const priceDetail = makePriceDetail(
        estimate,
        estimate.surgeryFlg, //← 0 or 1
        1,
        estimate.treatmentItems
      )
      const displayPriceDetail = Object.fromEntries(
        Object.entries(priceDetail).map(([key, value]) => {
          return key === 'pledgeRate' || key === 'discountRate'
            ? [key, `${value} %`]
            : [key, `￥${value}`]
        })
      )
      return [displayPriceDetail]
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-list-table {
  width: 100%;
  font-size: 15px;
  > .table-header {
    box-sizing: border-box;
    display: flex;
    height: 40px;
    border: 1px solid #{$light-grey};
    background-color: #{$pale};
    > .cell {
      display: flex;
      align-items: center;
      &.date {
        width: 320px;
        justify-content: center;
      }
      &.disease-name {
        flex: 1;
        padding-left: 150px;
      }
    }
    > .cell ~ .cell {
      border-left: 1px solid #{$skin};
    }
  }
  > .table-body {
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    border: solid #{$light-grey};
    border-width: 0px 1px 1px 1px;

    > .row {
      width: 100%;
      &:nth-child(even) {
        background-color: #{$white_f7};
      }
      > .upper {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: space-between;
        > .texts {
          height: 100%;
          display: flex;
          align-items: center;
          > .date {
            width: 320px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          > .disease-name {
            flex: 1;
            padding-left: 70px;
          }
        }
      }
      > .under {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 20px 20px 20px;
        > .left {
          width: calc(55% - 10px);
        }
        > .right {
          width: calc(45% - 10px);
          margin-top: 27px;
        }
      }
    }
    > .row ~ .row {
      border-top: 1px solid #{$light-grey};
    }
  }
}
</style>
