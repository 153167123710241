<template>
  <div class="treatment-item-input-table">
    <div class="table">
      <div class="table-header">
        <div class="cell name">診療項目内容</div>
        <div class="cell insurance-flg">保険</div>
        <div class="cell tax-exempt-flg">非課税</div>
        <div class="cell unit-price">単価（￥）</div>
        <div class="cell amount">数量</div>
        <div class="cell discount-rate">割引（%）</div>
        <div class="cell discount-price">値引（￥）</div>
        <div class="cell subtotal }">計</div>
      </div>
      <div class="table-body">
        <div class="cell name">
          <div class="text" :title="treatmentItem.name">
            {{ treatmentItem.name }}
          </div>
          <div
            v-if="isMedicine && !detailFlg"
            class="image-wrap plus"
            @click="$emit('click-plus')"
          >
            <img
              class="image plus"
              src="@/assets/images/circle_plus_orange.png"
            />
          </div>
        </div>
        <div class="cell insurance-flg">
          <base-select-box
            :selectData="selectData"
            :styles="{ height: '28px' }"
            v-model="treatmentItem.insuranceFlg"
          />
        </div>
        <div class="cell tax-exempt-flg">
          <base-select-box
            :selectData="selectData"
            :styles="{ height: '28px' }"
            v-model="treatmentItem.taxExemptFlg"
          />
        </div>
        <div class="cell unit-price">
          <validation-provider
            class="validation-provider"
            :rules="{ intRule: true }"
            v-slot="{ errors }"
            ><base-text-box
              :value="treatmentItem.unitPrice"
              :maxlength="7"
              :styles="textBoxStyles"
              :fullWidthNumbersToHalfWidthNumbers="true"
              @input="inputNum($event, 'unitPrice')"
              @blur="blur('unitPrice')"
              data-test="unit-price-text-box"
            />
            <div v-if="errors[0]" class="error" data-test="unit-price-error">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="cell amount">
          <validation-provider
            class="validation-provider"
            :rules="{ floatRule: isMedicine, intRule: !isMedicine }"
            v-slot="{ errors }"
            ><base-text-box
              data-test="amount-text-box"
              :value="treatmentItem.amount"
              :maxlength="5"
              :styles="textBoxStyles"
              :fullWidthNumbersToHalfWidthNumbers="true"
              @input="inputNum($event, 'amount')"
              @blur="blur('amount')"
            />
            <div v-if="errors[0]" class="error" data-test="amount-error">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="cell discount-rate">
          <validation-provider
            class="validation-provider"
            :rules="{ intRule: true, percentRule: true }"
            v-slot="{ errors }"
            ><base-text-box
              data-test="discount-rate-text-box"
              :value="treatmentItem.discountRate"
              :maxlength="3"
              :styles="textBoxStyles"
              :fullWidthNumbersToHalfWidthNumbers="true"
              @input="inputNum($event, 'discountRate')"
              @blur="blur('discountRate')"
            />
            <div v-if="errors[0]" class="error" data-test="discount-rate-error">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="cell discount-price">
          <validation-provider
            class="validation-provider"
            :rules="{ intRule: true }"
            v-slot="{ errors }"
            ><base-text-box
              data-test="discount-price-text-box"
              :value="treatmentItem.discountPrice"
              :maxlength="7"
              :styles="textBoxStyles"
              :fullWidthNumbersToHalfWidthNumbers="true"
              @input="inputNum($event, 'discountPrice')"
              @blur="blur('discountPrice')"
            />
            <div
              v-if="errors[0]"
              class="error"
              data-test="discount-price-error"
            >
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <div class="cell subtotal">
          <div class="text number" :title="subtotal" data-test="subtotal">
            ￥{{ subtotal.toLocaleString() }}
          </div>
        </div>
      </div>
      <div v-if="prescriptionFlg" class="table-footer">
        <div class="cell prescription">
          <div class="label">
            <div class="title-text">処方</div>
            <base-button-trash
              v-if="!detailFlg"
              class="trash-button"
              :styles="{ width: '28px', height: '28px' }"
              @click="deletePrescription"
            />
          </div>
          <div class="value">
            <validation-provider
              class="validation-provider"
              :rules="{ notSurrogatePair: true, maxRule: 255 }"
              v-slot="{ errors }"
              ><base-multiple-lines-text-box
                class="text-form"
                data-test="prescription-textarea"
                :maxlength="255"
                :placeholder="'例 １回 ○ g １日△回 朝夕食後 ×日分'"
                :styles="{
                  width: '640px',
                  height: '57px',
                  padding: '5px 20px 5px 8px'
                }"
                v-model="treatmentItem.prescription"
              />
              <div
                v-if="errors[0]"
                class="error"
                data-test="prescription-error"
              >
                {{ errors[0] }}
              </div>
            </validation-provider>
            <div class="cross-mark" @click="$emit('click-cross')">
              <div class="cross"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox.vue'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { calculateTreatmentItemSubtotal } from '@/utils/price_calculation'

export default {
  name: 'TreatmentItemInputTable',

  components: {
    BaseSelectBox,
    BaseTextBox,
    BaseButtonTrash,
    BaseMultipleLinesTextBox,
    ValidationProvider
  },

  props: {
    treatmentItem: { type: Object },
    detailFlg: { type: Boolean, default: false },
    insuranceType: { type: String, default: '' }
  },

  data() {
    return {
      textBoxStyles: { width: '80px', height: '28px', textAlign: 'right' },
      selectData: [
        { id: 1, name: '〇' },
        { id: 0, name: '✕' }
      ],
      popupFlg: false,
      prescriptionFlg: false
    }
  },

  computed: {
    isMedicine() {
      return (
        this.treatmentItem.medicineId > 0 ||
        this.treatmentItem.medicineOriginalId > 0
      )
    },
    existsPrescription() {
      return this.isMedicine && this.treatmentItem.prescription.length > 0
    },
    subtotal() {
      return calculateTreatmentItemSubtotal(
        this.insuranceType,
        this.treatmentItem
      )
    }
  },

  created() {
    this.prescriptionFlg = this.existsPrescription || this.detailFlg
  },

  methods: {
    deletePrescription() {
      this.$emit('click-trash')
      this.prescriptionFlg = false
    },
    blur(field) {
      if (this.treatmentItem[field] === '') this.treatmentItem[field] = 0
    },
    inputNum(value, field) {
      const isInt = /^([0-9]\d*)$/.test(value)
      const newValue = isInt ? Number(value) : value
      this.treatmentItem[field] = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$light-grey};
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.treatment-item-input-table {
  > .table {
    width: 834px;
    > .table-header {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: #{$very-light-shade-orange};
      border: solid $border-color;
      border-width: 1px 1px 1px 1px;
      > .cell {
        box-sizing: border-box;
        width: 90px;
        padding: 0;
        line-height: 28px;
        border-right: solid 1px #{$light-grey};
        font-size: 13px;
        text-align: center;
        font-feature-settings: 'palt';
        &:last-child {
          border-right: none;
        }
        &.name {
          width: 254px;
          padding: 0 8px;
          text-align: left;
        }
        &.insurance-flg,
        &.tax-exempt-flg {
          width: 60px;
        }
        &.subtotal {
          width: 100px;
        }
      }
    }
    > .table-body {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: #{$white};
      border: solid $border-color;
      border-width: 0 1px 1px 1px;
      > .cell {
        box-sizing: border-box;
        @include center;
        width: 90px;
        height: 34px;
        border-right: solid 1px #{$light-grey};
        font-size: 13px;
        &:last-child {
          border-right: none;
        }
        &.name {
          width: 254px;
        }
        &.insurance-flg,
        &.tax-exempt-flg {
          width: 60px;
        }
        &.subtotal {
          width: 100px;
        }
        > .text {
          flex: 1;
          line-height: 34px;
          padding: 0 8px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.number {
            text-align: right;
          }
        }
        > .image-wrap {
          @include center;
          width: 24px;
          height: 24px;
          cursor: pointer;
          z-index: 1;
          border-radius: 50%;
          &.plus:hover {
            > .image {
              border-radius: 50%;
              box-shadow: 0 0 4px;
            }
          }
        }
      }
    }
    > .table-footer {
      > .prescription {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 68px;
        background-color: #{$white};
        border: solid $border-color;
        border-width: 0 1px 1px 1px;
        > .label {
          @include center;
          width: 150px;
          font-size: 13px;
          background-color: #{$very-light-shade-orange};
          border-right: solid 1px #{$light-grey};
          position: relative;
          > .trash-button {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
          }
        }
        > .value {
          flex: 1;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding-left: 6px;
          position: relative;
          > .cross-mark {
            position: absolute;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #{$gray};
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
            > .cross {
              position: relative;
              top: 9px;
              right: -5px;
              width: 10px;
              &::before,
              &::after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 2px;
                background-color: #{$white};
                border-radius: 2px;
              }
              &::before {
                transform: rotate(45deg);
              }
              &::after {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
  }
}
.validation-provider {
  position: relative;
  > .error {
    position: absolute;
    z-index: 10;
    height: 18px;
    line-height: 18px;
    padding: 0 3px;
    background-color: #{$white};
    color: #{$tomato};
    font-size: 12px;
    text-align: left;
    white-space: nowrap;
  }
}
</style>
