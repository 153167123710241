<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="treatment-item-detail-input-popup"
      @keyup.esc.stop="checkDiff"
      tabindex="-3"
    >
      <validation-observer
        class="validation-observer"
        ref="observer"
        v-slot="{ invalid }"
      >
        <popup
          :title="'診療項目内容 詳細'"
          :invalid="invalid"
          :contentStyle="{}"
          @cancel="checkDiff"
          @click-close-mark="checkDiff"
          @decision="registerDetail"
        >
          <template v-slot:content>
            <div class="content">
              <div class="treatment-item">
                <treatment-item-input-table
                  class="input-table"
                  :treatmentItem="inputTreatmentItem"
                  :detailFlg="true"
                  :insuranceType="insuranceType"
                  @click-cross="clearPrescription"
                />
              </div>
              <div class="prescription">
                <prescription-detail-input-form
                  :showFlg="true"
                  :dailyDose="dailyDose"
                  :singleDose="singleDose"
                  :options="options"
                  :takingTime="takingTime"
                  :oral="oral"
                  :period="period"
                  :periodTypeSelectData="periodTypeSelectData"
                />
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        v-if="popup.show"
        :type="popup.type"
        :title="popup.title"
        :buttons="popup.buttons"
        :layerNumber="4"
        @cancel="closePopup"
        @decision="$emit('cancel')"
        >{{ popup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import TreatmentItemInputTable from '@/components/parts/molecules/TreatmentItemInputTable'
import PrescriptionDetailInputForm from '@/components/parts/molecules/PrescriptionDetailInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'TreatmentItemDetailInputPopup',

  components: {
    Popup,
    TreatmentItemInputTable,
    PrescriptionDetailInputForm,
    FocusTrap,
    ValidationObserver,
    AnnouncePopup
  },

  mixins: [CheckPopupInputDifference],

  props: {
    treatmentItem: { type: Object },
    originalPopupPreventLeaveFlg: { type: Boolean },
    insuranceType: { type: String, default: '' }
  },

  data() {
    return {
      inputTreatmentItem: {},
      initialData: [],
      popup: {
        show: false,
        type: '',
        title: '',
        buttons: [],
        message: ''
      },
      singleDose: {
        text: '',
        selectUnitType: 1
      },
      dailyDose: {
        selectValue: 0,
        freeText: ''
      },
      options: [
        { id: 1, eachValue: 1, labelName: '１日１回' },
        { id: 2, eachValue: 2, labelName: '１日２回' },
        { id: 3, eachValue: 3, labelName: '１日３回' },
        { id: 4, eachValue: 4, labelName: '１日４回' },
        { id: 5, eachValue: 5, labelName: '２日に１回' },
        { id: 6, eachValue: 6, labelName: '３日に１回' },
        { id: 7, eachValue: 7, labelName: '４日に１回' },
        { id: 8, eachValue: 8, labelName: '２週に１回' },
        { id: 9, eachValue: 9, labelName: '月に１回' },
        { id: 10, eachValue: 10, labelName: '頓服' },
        { id: 11, eachValue: 11, labelName: '' }
      ],
      takingTime: {
        morningFlg: false,
        noonFlg: false,
        nightFlg: false,
        beforeMealFlg: false,
        afterMealFlg: false,
        betweenMealFlg: false,
        beforeSleepFlg: false,
        useAsNeededFlg: false,
        takingTimeFreeFlg: false,
        takingTimeFree: ''
      },
      oral: {
        oralAdministrationFlg: false,
        subcutaneousAdministrationFlg: false,
        subcutaneousDripFlg: false,
        noseDropsFlg: false,
        earDropsBothFlg: false,
        earDropsLeftFlg: false,
        earDropsRightFlg: false,
        eyesBothFlg: false,
        eyesLeftFlg: false,
        eyesRightFlg: false,
        rectalAdministrationFlg: false,
        intravaginalAdministrationFlg: false,
        oralFreeFlg: false,
        oralFree: ''
      },
      period: {
        text: '',
        selectPeriod: 1
      },
      periodTypeSelectData: [
        { id: 1, name: '時間分' },
        { id: 2, name: '日分' },
        { id: 3, name: '週分' },
        { id: 4, name: 'ヶ月分' },
        { id: 5, name: '年分' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById'
    }),
    diffFlg() {
      return this.originalPopupPreventLeaveFlg || this.hasChanges
    },
    hasChanges() {
      return !_.isEqual(this.inputTreatmentItem, this.initialData)
    }
  },

  watch: {
    singleDose: {
      deep: true,
      handler: function() {
        this.addText()
      }
    },
    dailyDose: {
      deep: true,
      handler: function() {
        this.addText()
      }
    },
    takingTime: {
      deep: true,
      handler: function() {
        this.addText()
      }
    },
    oral: {
      deep: true,
      handler: function() {
        this.addText()
      }
    },
    period: {
      deep: true,
      handler: function() {
        this.addText()
      }
    }
  },

  created() {
    this.singleDose.selectUnitType = this.treatmentItem?.unitTypeId || 1
    const { unitTypeId, ...treatmentItem } = this.treatmentItem
    this.inputTreatmentItem = { ...treatmentItem }
    this.initialData = { ...treatmentItem }
    this.$nextTick(() => {
      this.$refs.observer.validate()
    })
  },

  beforeDestroy() {
    if (this.originalPopupPreventLeaveFlg) {
      this.$store.dispatch('petorelu/popupNgLeave')
    }
  },

  methods: {
    checkDiff() {
      if (this.hasChanges) {
        this.popup = {
          show: true,
          type: 'alert',
          title: '確認',
          buttons: ['キャンセル', '閉じる'],
          message:
            '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        }
      } else {
        this.$emit('cancel')
      }
    },
    closePopup() {
      this.popup = {
        show: false,
        type: '',
        title: '',
        buttons: [],
        message: ''
      }
    },
    registerDetail() {
      this.$emit('decision', this.inputTreatmentItem)
    },
    clearPrescription() {
      this.inputTreatmentItem.prescription = ''
    },
    addText() {
      let addText = ''
      addText += this.addSingleDose()
      addText += this.addDailyDose()
      addText += this.addTakingTime()
      addText += this.addOral()
      addText += this.addPeriod()
      this.inputTreatmentItem.prescription = addText
      this.$nextTick(() => {
        this.$refs.observer.validate()
      })
    },
    addSingleDose() {
      if (this.singleDose.text === '') return ''
      const unitTypeName = this.getMasterDatum(
        'unitTypes',
        this.singleDose.selectUnitType
      ).name
      return '1回 ' + this.singleDose.text + unitTypeName
    },
    addDailyDose() {
      if (this.dailyDose.selectValue === 0) return ''
      const option = this.options.find(
        v => v.eachValue === this.dailyDose.selectValue
      )
      const name =
        option.eachValue === 11 ? this.dailyDose.freeText : option.labelName
      return ' ' + name
    },
    addTakingTime() {
      let text = ''
      text += this.takingTime.morningFlg ? ' 朝' : ''
      text += this.takingTime.noonFlg ? ' 昼' : ''
      text += this.takingTime.nightFlg ? ' 夜' : ''
      text += this.takingTime.beforeMealFlg ? ' 食前' : ''
      text += this.takingTime.afterMealFlg ? ' 食後' : ''
      text += this.takingTime.betweenMealFlg ? ' 食間' : ''
      text += this.takingTime.beforeSleepFlg ? ' 寝る前' : ''
      text += this.takingTime.useAsNeededFlg ? ' 頓服' : ''
      text += this.takingTime.takingTimeFreeFlg
        ? ' ' + this.takingTime.takingTimeFree
        : ''
      return text
    },
    addOral() {
      let text = ''
      text += this.oral.oralAdministrationFlg ? ' 経口投与' : ''
      text += this.oral.subcutaneousAdministrationFlg ? ' 皮下投与' : ''
      text += this.oral.subcutaneousDripFlg ? ' 皮下点滴' : ''
      text += this.oral.noseDropsFlg ? ' 点鼻' : ''
      text += this.oral.earDropsBothFlg ? ' 点耳(両)' : ''
      text += this.oral.earDropsLeftFlg ? ' 点耳(左)' : ''
      text += this.oral.earDropsRightFlg ? ' 点耳(右)' : ''
      text += this.oral.eyesBothFlg ? ' 眼(両)' : ''
      text += this.oral.eyesLeftFlg ? ' 眼(左)' : ''
      text += this.oral.eyesRightFlg ? ' 眼(右）' : ''
      text += this.oral.rectalAdministrationFlg ? ' 直腸内投与' : ''
      text += this.oral.intravaginalAdministrationFlg ? ' 膣内投与' : ''
      text += this.oral.oralFreeFlg ? ' ' + this.oral.oralFree : ''
      return text
    },
    addPeriod() {
      if (this.period.text === '') return ''
      const periodTypeName = this.periodTypeSelectData.find(
        v => v.id === this.period.selectPeriod
      ).name
      return ' ' + this.period.text + periodTypeName
    }
  }
}
</script>

<style lang="scss" scoped>
.treatment-item-detail-input-popup {
  .content {
    box-sizing: border-box;
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .treatment-item {
      margin-top: 27px;
    }
    > .prescription {
      margin-top: 20px;
      ::v-deep .prescription-detail-input-form {
        > .title {
          display: none;
        }
        > .prescription-table {
          width: 834px;
        }
      }
    }
  }
}
</style>
