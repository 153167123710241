<template>
  <div class="medical-contents-history" ref="medicalContentsHistory">
    <div class="top-right">
      <div class="history-type-select">
        <div class="label">変更履歴</div>
        <base-select-box :selectData="showTypes" v-model="showType" />
      </div>
      <div class="icons">
        <img
          src="@/assets/images/float_window.png"
          @mousedown="$emit('move')"
          data-test="float-window"
        />
        <img src="@/assets/images/cross.png" @click="() => $emit('close')" />
        <!-- ドラッグサンプル
        <img
          src="@/assets/images/float_window.png"
          @dragstart="dragstart"
          data-test="float-window"
        /> -->
      </div>
    </div>
    <div class="search">
      <history-search
        :text="searchText"
        :placeholder="'診療内容'"
        :startDate="startDate"
        :endDate="endDate"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        @input="inputSearchText"
      />
      <div class="order">
        <base-select-box
          :selectData="orderTypes"
          :value="orderType"
          @input="changeOrderType"
        />
      </div>
    </div>
    <div class="list">
      <div class="patient">
        <history-patient-detail :patient="getPatient(patientId)" />
      </div>
      <div
        v-if="displayMedicalContents.length > 0"
        class="medical-contents"
        ref="medicalContents"
      >
        <medical-contents-history-detail
          v-for="medicalContent in displayMedicalContents"
          :key="medicalContent.id"
          :medicalContent="medicalContent"
          :searchRegExp="searchRegExp"
          :trimSearchText="trimSearchText"
          :displayCount="displayCount"
          @scroll-bottom="scrollBottom"
        ></medical-contents-history-detail>
      </div>
      <div v-else class="no-data">対象のデータはありません。</div>
    </div>
    <announce-popup v-if="popup.opened" v-bind="popup" @close="popup.close">{{
      popup.message
    }}</announce-popup>
  </div>
</template>

<script>
import HistorySearch from '@/components/parts/molecules/HistorySearch'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import HistoryPatientDetail from '@/components/parts/molecules/HistoryPatientDetail'
import MedicalContentsHistoryDetail from '@/components/parts/organisms/MedicalContentsHistoryDetail'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import { escapeRegExp, FULL_WIDTH_COLON } from '@/utils/define'

export default {
  name: 'MedicalContentsHistory',

  components: {
    HistorySearch,
    BaseSelectBox,
    HistoryPatientDetail,
    MedicalContentsHistoryDetail,
    AnnouncePopup
  },

  props: {
    patientId: { type: Number }
  },

  data() {
    return {
      displayCount: 7,
      patientMedicalContents: [],
      searchedMedicalContents: [],
      displayMedicalContents: [],
      searchText: '',
      startDate: '',
      endDate: '',
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      timeoutId: null,
      delay: 300,
      showType: 0,
      showTypes: [
        { id: 0, name: '非表示' },
        { id: 1, name: '表示' }
      ],
      popup: {
        opened: false,
        type: 'failure',
        title: '失敗',
        message:
          'カルテの履歴で使用する画像の取得に失敗しました。\n一度カルテの履歴を閉じてから再度開いてお試し下さい。',
        buttons: ['閉じる'],
        close: () => {
          this.popup.opened = false
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      getPatient: 'patients/getDataById',
      getMedicalContentsByPatientId: 'medicalContents/getDataByPatientId',
      getMedicalContentsByPatientIdIncludeDel:
        'medicalContents/getDataByPatientIdIncludeDel',
      getMedicalContentHistory: 'medicalContents/getDataByOriginalIdIncludeDel',
      getMedicalRecordById: 'medicalRecords/getDataById',
      getMedicalRecordsByPatientIdIncludeDel:
        'medicalRecords/getDataByPatientIdIncludeDel',
      karteUploadImages: 'uploadImages/getKarteUploadImages',
      karteMedicalContentImages:
        'medicalContentImages/getKarteMedicalContentImages'
    }),
    trimSearchText() {
      return this.searchText.toLowerCase().replace(/\s+/g, '')
    },
    searchRegExp() {
      const escapedText = escapeRegExp(this.trimSearchText)
      return escapedText === '' ? null : new RegExp(escapedText, 'ig')
    }
  },

  watch: {
    showType: function() {
      this.initialSetData()
    }
  },

  async created() {
    this.initialSetData()
    this.$store.watch(
      () => this.$store.getters['medicalContents/getData'],
      () => this.initialSetData()
    )
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    getUploadImageIds(medicalContentOriginalIdsSet) {
      /*
        表示している診療内容に紐づいているuploadImageのidをstoreから取得する処理。
        この処理が走る前に、患者に紐づいたカルテ関連の各データを取得するapi(/medical-records/patient-all-data)で、患者に紐づいたuploadImage(imageカラムは入っていない)を全てフロント側に持ってきているため、storeのstateから取得できる。
      */
      return Object.values(this.karteUploadImages)
        .filter(uploadImage =>
          medicalContentOriginalIdsSet.has(uploadImage.medicalContentOriginalId)
        )
        .map(uploadImage => uploadImage.id)
    },
    getUploadImageIdsOfMedicalContentImage(medicalContentOriginalIdsSet) {
      /*
       表示している診療内容の各シェーマ画像の背景で使用しているuploadImageのidを取得する処理。
        値をバックエンドに送って該当のuploadImageを取得する。
        目的
        →シェーマ画像で他の診療内容のuploadImageを背景で使用した時に、
         そのuploadImageはシェーマ画像をセットした診療内容には紐づいていないため、
         uploadImageのmedicalContentOriginalId経由では取得できない。
         そのため、medicalContentImageのuploadImageIdから該当のuploadImageを取得する必要がある。
      */
      return Object.values(this.karteMedicalContentImages)
        .filter(
          medicalContentImage =>
            medicalContentImage.uploadImageId > 0 &&
            medicalContentOriginalIdsSet.has(
              medicalContentImage.medicalContentOriginalId
            )
        )
        .map(medicalContentImage => medicalContentImage.uploadImageId)
    },
    getRequiredUploadImageIdsOfMultipleKarte(medicalContentsToGetImage) {
      const medicalContentOriginalIdsSet = new Set(
        medicalContentsToGetImage.map(v => v.originalId)
      )
      const uploadImageIdsOfUploadImage = this.getUploadImageIds(
        medicalContentOriginalIdsSet
      )
      const uploadImageIdsOfMedicalContentImage = this.getUploadImageIdsOfMedicalContentImage(
        medicalContentOriginalIdsSet
      )
      const uploadImageIds = Array.from(
        new Set(
          uploadImageIdsOfUploadImage.concat(
            uploadImageIdsOfMedicalContentImage
          )
        )
      )
      const acquiredUploadImageIdsSet = this.$store.getters[
        'uploadImages/getAcquiredUploadImageIdsSet'
      ]
      const requiredUploadImageIds = uploadImageIds.filter(
        v => !acquiredUploadImageIdsSet.has(v)
      )
      return requiredUploadImageIds
    },
    async fetchUploadImagesOfMultipleKarte(requiredUploadImageIds) {
      /*
        表示している各診療内容内にある高解像度の画像（uploadImageのimageカラム）を取得する処理。
        imageカラムが入ったuploadImageごと取得してstoreのstateにセットしている。
      */
      const results = await Promise.all(
        requiredUploadImageIds.map(uploadImageId =>
          this.$store.dispatch(
            'uploadImages/fetchUploadImageOfOpenedKarte',
            uploadImageId
          )
        )
      )
      const isSomeApiFailed = results.some(result => result !== 'success')
      if (isSomeApiFailed) {
        this.popup.opened = true
      }
    },
    tryFetchUploadImagesOfMultipleKarte(medicalContentsToGetImage) {
      const requiredUploadImageIds = this.getRequiredUploadImageIdsOfMultipleKarte(
        medicalContentsToGetImage
      )
      if (requiredUploadImageIds.length > 0) {
        this.fetchUploadImagesOfMultipleKarte(requiredUploadImageIds)
      }
    },
    setStartDisplayContents(initialDisplayFlg = false) {
      this.displayCount = 7
      this.displayMedicalContents = this.searchedMedicalContents.filter(
        (_, i) => i < this.displayCount
      )
      if (!initialDisplayFlg && this.$refs.medicalContents) {
        this.$refs.medicalContents.scrollTo(0, 0)
      }
    },
    initialSetData() {
      const storePatientData =
        this.showType === 0
          ? this.getMedicalContentsByPatientId(this.patientId) || []
          : this.getMedicalContentsByPatientIdIncludeDel(this.patientId) || []
      this.patientMedicalContents = this.makeMedicalContents(storePatientData)
        .sort((a, b) => this.sortByDate(a, b))
        .map((v, i) => ({ ...v, number: i + 1 }))
      if (
        this.searchText === '' &&
        this.startDate === '' &&
        this.endDate === ''
      ) {
        this.searchedMedicalContents = this.patientMedicalContents
        this.setStartDisplayContents(true)
        this.tryFetchUploadImagesOfMultipleKarte(this.displayMedicalContents)
      } else {
        this.searchMedicalContents()
      }
    },
    makeMedicalContents(storePatientData) {
      return storePatientData.map(v => {
        const medicalRecord = this.getMedicalRecordById(v.medicalRecordId)
        const history = this.getMedicalContentHistory(v.originalId).slice()
        //↓this.getMedicalContentHistoryのデータはid昇順を保証していないので、sortでid昇順に並び替える
        history.sort((a, b) => a.id - b.id)
        const latest = history[history.length - 1]
        const latestDate = this.getMedicalRecordById(latest.medicalRecordId)
          .date
        const replaceSearchContent = v.searchContent.replace(
          new RegExp(FULL_WIDTH_COLON, 'g'),
          '\n'
        )
        return {
          ...v,
          date: medicalRecord.date,
          formatDate: moment(medicalRecord.date, 'YYYYMMDD').format(
            'YYYY年MM月DD日（dd）'
          ),
          latest: {
            id: latest.id,
            date: latestDate,
            delFlg: latest.delFlg
          },
          original: {
            id: history[0].id,
            inputStaffId: history[0].inputStaffId,
            createdAt: history[0].createdAt
          },
          searchAllContent: replaceSearchContent + v.searchImageContent
          //searchContentは使用していないので、replaceSearchContentに書き換えていないです
        }
      })
    },
    sortByDate(a, b) {
      return a.originalId === b.originalId
        ? a.delFlg !== b.delFlg
          ? a.delFlg - b.delFlg
          : a.deleteStaffId !== 0 //delFlg:1同士の比較になる。同じoriginalIdで最新の診療内容(delFlg:0)は1つだけなので、delFlg:0同士の比較はない
          ? -1
          : b.deleteStaffId !== 0
          ? 1
          : this.orderType === 0
          ? b.id - a.id
          : a.id - b.id
        : this.sortByOrderType(
            { date: a.latest.date, originalId: a.originalId },
            { date: b.latest.date, originalId: b.originalId }
          )
    },
    sortByOrderType(a, b) {
      if (this.orderType === 0) {
        return a.date === b.date
          ? b.originalId - a.originalId
          : b.date < a.date
          ? -1
          : 1
      } else {
        return a.date === b.date
          ? a.originalId - b.originalId
          : a.date < b.date
          ? -1
          : 1
      }
    },
    searchMedicalContents() {
      this.searchedMedicalContents = this.patientMedicalContents
        .filter(
          v =>
            this.filterByContent(v, this.trimSearchText) &&
            this.filterByDate(v, this.startDate, this.endDate)
        )
        .sort((a, b) => this.sortByDate(a, b))
        .map((v, i) => {
          return { ...v, number: i + 1 }
        })
      this.setStartDisplayContents()
      this.tryFetchUploadImagesOfMultipleKarte(this.displayMedicalContents)
    },
    filterByContent(medicalContent, searchText) {
      return medicalContent.searchAllContent.toLowerCase().includes(searchText)
    },
    filterByDate(medicalContent, startDate, endDate) {
      return startDate !== '' && endDate !== ''
        ? startDate <= medicalContent.date && medicalContent.date <= endDate
        : startDate !== ''
        ? startDate <= medicalContent.date
        : endDate !== ''
        ? medicalContent.date <= endDate
        : true
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
      this.searchMedicalContents()
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
      this.searchMedicalContents()
    },
    inputSearchText(text) {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.searchText = text
        this.searchMedicalContents()
      }, this.delay)
    },
    changeOrderType(orderType) {
      this.orderType = orderType
      this.searchedMedicalContents = this.searchedMedicalContents
        .sort((a, b) => this.sortByDate(a, b))
        .map((v, i) => {
          return { ...v, number: i + 1 }
        })
      this.setStartDisplayContents()
      this.tryFetchUploadImagesOfMultipleKarte(this.displayMedicalContents)
    },
    scrollBottom() {
      this.displayCount += 7
      const addData = this.searchedMedicalContents.filter(
        (_, i) => i >= this.displayCount - 7 && i < this.displayCount
      )
      this.displayMedicalContents = this.displayMedicalContents.concat(addData)
      this.tryFetchUploadImagesOfMultipleKarte(addData)
    }
    // ドラッグサンプル
    // dragstart(e) {
    //   console.log('dragstart')
    //   const comp = this.$refs.medicalContentsHistory
    //   console.log(comp)
    //   e.dataTransfer.setDragImage(comp, 600, 20)
    // }
  }
}
</script>

<style lang="scss" scoped>
.medical-contents-history {
  position: relative;
  box-sizing: border-box;
  width: 650px;
  min-width: 650px;
  height: 881px;
  border: solid 1px #{$light-grey};
  background-color: #{$white};
  display: flex;
  flex-direction: column;
  > .top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 235px;
    > .history-type-select {
      display: flex;
      align-items: center;
      padding-top: 2px;
      > .label {
        font-size: 13px;
        padding-right: 10px;
      }
    }
    > .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 46px;
      > img {
        cursor: pointer;
      }
    }
  }
  > .search {
    box-sizing: border-box;
    position: relative;
    padding: 12px 15px;
    background-color: rgba(252, 225, 204, 0.4);
    > .order {
      position: absolute;
      right: 15px;
      bottom: 12px;
    }
  }
  > .list {
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    height: 0px;
    display: flex;
    flex-direction: column;
    background-color: #{$white_f7};
    > .patient {
      padding: 8px 15px;
    }
    > .medical-contents {
      box-sizing: border-box;
      width: 100%;
      flex: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0 0 5px 15px;
    }
    > .no-data {
      padding: 15px;
      font-size: 13px;
      font-weight: bold;
    }
  }
}
</style>
