<template>
  <div class="patient-medical-payments">
    <div class="area owner-patient">
      <owner-patient-detail
        :owner="getOwner(ownerId)"
        :patient="getPatient(patientId)"
      />
    </div>
    <div class="area search">
      <search-area
        :textBoxLabel="'検索単語'"
        :textValue="searchWord"
        :placeholder="'診断名、診療項目内容'"
        :periodFlg="true"
        :periodLabel="'期間'"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        v-model="searchWord"
      />
    </div>
    <div class="area search-order">
      <div class="order-result">
        検索結果：{{ displayMedicalPayments.length }}件({{
          medicalPayments.length
        }}件中)
      </div>
      <div class="order-setting">
        <base-button-small-white
          v-if="lookOnlyFlg === 0"
          class="toggle-show-flg-button"
          :styles="{ width: '150px' }"
          @click="toggleFlg('deleteButtonShowFlg')"
          >{{ toggleButtonText }}</base-button-small-white
        >
        <div class="label">並び替え：診療日</div>
        <base-select-box :selectData="orderTypes" v-model="orderType" />
      </div>
    </div>
    <div class="area list">
      <medical-payment-list-table
        v-if="displayMedicalPayments.length > 0"
        :medicalPayments="displayMedicalPayments"
        :patientMedicalPaymentsFlg="true"
        :patientId="patientId"
        :deleteButtonShowFlg="deleteButtonShowFlg"
        ref="medicalPaymentListTable"
        @click-item="gotoMedicalRecordEditPage"
        @go-to-payment-show-page="gotoPaymentShowPage"
        @go-to-payment-new-page="gotoPaymentNewPage"
        @open-delete-popup="openDeletePopup"
      >
      </medical-payment-list-table>
    </div>
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="closePopup"
      @decision="decide"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import SearchArea from '@/components/parts/molecules/SearchArea'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import MedicalPaymentListTable from '@/components/parts/organisms/MedicalPaymentListTable'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import { compareMPH } from '@/utils/compare'

export default {
  name: 'PatientMedicalPayments',

  components: {
    OwnerPatientDetail,
    SearchArea,
    BaseButtonSmallWhite,
    BaseSelectBox,
    MedicalPaymentListTable,
    AnnouncePopup
  },

  props: {
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      searchWord: '',
      startDate: '',
      endDate: '',
      deleteButtonShowFlg: false,
      deleteMedicalPayment: {},
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      decide: () => {},
      waitFlg: false,
      scrollTop: 0,
      parentScrollTop: 0,
      deleteOperationFlg: false,
      timeoutId: null
    }
  },

  computed: {
    ...mapGetters({
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getMedicalRecord: 'medicalRecords/getDataByOriginalId',
      getMedicalPaymentsByPatientId: 'medicalPayments/getDataByPatientId',
      getMedicalTreatmentItemsByMedicalPaymentId:
        'medicalTreatmentItems/getDataByMedicalPaymentId',
      getDisease: 'diseases/getDataById',
      getTreatment: 'treatments/getDataById',
      getMedicine: 'medicines/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    medicalPayments() {
      return this.getMedicalPaymentsByPatientId(this.patientId) || []
    },
    displayMedicalPayments() {
      return this.medicalPayments
        .map(v => {
          const medicalRecord = this.getMedicalRecord(v.medicalRecordOriginalId)
          const medicalTreatmentItems = (
            this.getMedicalTreatmentItemsByMedicalPaymentId(v.id) || []
          )
            .filter(
              item =>
                this.getTreatment(item.treatmentId) ||
                this.getMedicine(item.medicineId)
            )
            .map(item => {
              const name =
                this.getTreatment(item.treatmentId)?.name ||
                this.getMedicine(item.medicineId)?.name
              return { ...item, name }
            })
          return {
            ...v,
            date: medicalRecord.date,
            startTime: medicalRecord.startTime,
            diseaseName: this.makeDiseaseName(v),
            medicalTreatmentItems
          }
        })
        .filter(v => this.filterByDate(v) && this.filterByWord(v))
        .sort((a, b) => this.compare(a, b))
    },
    toggleButtonText() {
      return this.deleteButtonShowFlg ? '削除ボタン非表示' : '削除ボタン表示'
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    makeDiseaseName(medicalPayment) {
      const disease1 = this.getDisease(medicalPayment.disease1Id)
      const disease2 = this.getDisease(medicalPayment.disease2Id)
      const diseaseName =
        disease1 && disease2
          ? `${disease1.name},${disease2.name}`
          : disease1
          ? disease1.name
          : disease2
          ? disease2.name
          : '診断名なし'
      return diseaseName
    },
    filterByDate(medicalPayment) {
      return this.startDate !== '' && this.endDate !== ''
        ? this.startDate <= medicalPayment.date &&
            medicalPayment.date <= this.endDate
        : this.startDate !== ''
        ? this.startDate <= medicalPayment.date
        : this.endDate !== ''
        ? medicalPayment.date <= this.endDate
        : true
    },
    filterByWord(medicalPayment) {
      const searchWord = this.searchWord.toLowerCase().replace(/\s+/g, '')
      return (
        medicalPayment.diseaseName.toLowerCase().includes(searchWord) ||
        medicalPayment.medicalTreatmentItems.some(item =>
          item.name.toLowerCase().includes(searchWord)
        )
      )
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    toggleFlg(flgName) {
      this[flgName] = !this[flgName]
    },
    isMultipleHospitalization(medicalPayment) {
      return Boolean(
        (medicalPayment.startHospitalizationFlg &&
          !medicalPayment.endHospitalizationFlg) ||
          medicalPayment.inHospitalFlg ||
          (medicalPayment.endHospitalizationFlg &&
            !medicalPayment.startHospitalizationFlg)
      )
    },
    openDeletePopup(medicalPayment) {
      this.deleteMedicalPayment = medicalPayment
      const params = {
        patientId: 0,
        medicalRecordOriginalId: medicalPayment.medicalRecordOriginalId,
        hospitalizationId: 0
      }
      const isLocked = this.$store.getters['auth/isAnotherClientLocking'](
        params
      )
      if (isLocked) {
        this.$store.dispatch('petorelu/saveNextFunction', this.deleteConfirm)
        this.$store.dispatch('petorelu/showKarteLockPopup', {
          ...params,
          isTryingDelete: true
        })
      } else {
        this.deleteConfirm()
      }
    },
    deleteConfirm() {
      if (this.isMultipleHospitalization(this.deleteMedicalPayment)) {
        this.buttons = ['削除しない', 'すべて削除する']
        this.popupMessage =
          'この診療明細は入院期間の一部となっています。\nこの入院期間の診療明細をすべて削除してもよろしいですか？'
      } else {
        this.buttons = ['削除しない', '削除する']
        this.popupMessage = '削除してもよろしいですか？'
      }
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.decide = () => this.decideDelete()
      this.scrollTop = this.getScrollTop()
      this.parentScrollTop = this.$parent.$el.scrollTop
    },
    getScrollTop() {
      //テストで下記の処理は$refsを二段階以上で使用していてモック化できなかったため、
      //関数にしてモック化できるようにする
      return this.$refs.medicalPaymentListTable.$refs.scroller.$el.scrollTop
    },
    async decideDelete() {
      this.waitFlg = true
      const res = this.isMultipleHospitalization(this.deleteMedicalPayment)
        ? await this.$store.dispatch(
            'medicalPayments/deleteHospitalization',
            this.deleteMedicalPayment.originalId
          )
        : await this.$store.dispatch(
            'medicalPayments/delete',
            this.deleteMedicalPayment.id
          )
      if (res.result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
        this.deleteOperationFlg = true
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (res === 'redo' || res === 'valid payment') {
          this.popupMessage = '会計データがあるため削除できません'
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    },
    gotoMedicalRecordEditPage(medicalPayment) {
      const ownerId = this.ownerId
      const patientId = this.patientId
      const medicalRecordOriginalId = medicalPayment.medicalRecordOriginalId
      this.$router.push(
        `/main/karte/owners/${ownerId}/patients/${patientId}/medical-records/${medicalRecordOriginalId}/edit`
      )
    },
    gotoPaymentNewPage(medicalPayment) {
      const ownerId = this.ownerId
      const patientId = this.patientId
      const medicalPaymentOriginalId = medicalPayment.originalId
      this.$router.push(
        `/main/karte/owners/${ownerId}/patients/${patientId}/payments/${medicalPaymentOriginalId}/new`
      )
    },
    gotoPaymentShowPage(medicalPayment) {
      const ownerId = this.ownerId
      const patientId = this.patientId
      const medicalPaymentOriginalId = medicalPayment.originalId
      this.$router.push(
        `/main/karte/owners/${ownerId}/patients/${patientId}/payments/${medicalPaymentOriginalId}/show`
      )
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
      if (this.deleteOperationFlg) {
        //データ削除後にスクロール位置が自動的にずれてしまう。
        //原因ははっきりとわかっていないが、virtual-scrollerとfocus-trapの影響で削除ポップアップを閉じた後に
        //focusが別の診療明細の要素に移ったことでスクロールが自動的にされた可能性がある。
        //自動的にずれる処理自体を止める方法が見つからなかったため、その直後に元のスクロール位置に戻すようにした。
        this.$nextTick(() => {
          this.timeoutId = setTimeout(() => {
            this.scrollTo()
            this.$parent.$el.scrollTo(0, this.parentScrollTop) //大元の方のスクロールの位置もずれるので調整する
          }, 1)
          this.deleteOperationFlg = false
        })
      }
    },
    scrollTo() {
      //テストで下記の処理は$refsを二段階以上で使用していてモック化できなかったため、
      //関数にしてモック化できるようにする
      this.$refs.medicalPaymentListTable?.$refs.scroller.$el.scrollTo(
        0,
        this.scrollTop
      )
    },
    compare(a, b) {
      let result = 0
      if (a.date === b.date) {
        if (a.startTime === b.startTime) {
          result = compareMPH(a, b)
        } else {
          result = b.startTime < a.startTime ? -1 : 1
        }
      } else {
        result = b.date < a.date ? -1 : 1
      }
      return this.orderType === 0 ? result : -result
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-medical-payments {
  display: inline-block;
  min-width: 100%;
  > .owner-patient {
    margin-top: 10px;
  }
  > .search {
    margin-top: 40px;
  }
  > .search-order {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    > .order-setting {
      display: flex;
      align-items: center;
      > .toggle-show-flg-button {
        margin-right: 20px;
      }
      > .label {
        margin-right: 10px;
      }
    }
  }
  > .list {
    min-width: 1400px;
    margin-top: 15px;
    ::v-deep .table-body {
      max-height: 630px;
    }
  }
}
</style>
