import { render, staticRenderFns } from "./MedicalPaymentsHistoryDetail.vue?vue&type=template&id=4450e04b&scoped=true&"
import script from "./MedicalPaymentsHistoryDetail.vue?vue&type=script&lang=js&"
export * from "./MedicalPaymentsHistoryDetail.vue?vue&type=script&lang=js&"
import style0 from "./MedicalPaymentsHistoryDetail.vue?vue&type=style&index=0&id=4450e04b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4450e04b",
  null
  
)

export default component.exports