<template>
  <div class="examinations">
    <div class="area owner-patient">
      <owner-patient-detail
        :owner="getOwner(ownerId)"
        :patient="getPatient(patientId)"
      />
    </div>
    <div class="area search">
      <search-area
        :textBoxLabel="'検索単語'"
        :textValue="searchWord"
        :periodFlg="true"
        :periodLabel="'期間'"
        :radioButtonLabel="'表示'"
        :radioButtonValue="radioButtonValue"
        :radioButtonData="radioButtonData"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        @select-radio-data="changeRadioButtonValue"
        v-model="searchWord"
      />
    </div>
    <div class="area search-order">
      <div class="order-result">
        検索結果：{{ filteredResults.length }}件({{
          groupedResults.length
        }}件中)
      </div>
      <div class="order-setting">
        <div class="label">並び替え：登録日</div>
        <base-select-box :selectData="orderTypes" v-model="orderType" />
      </div>
    </div>
    <div class="area list">
      <examination-list-table :displayResults="filteredResults">
        <template #buttons="{ resultGroup }">
          <div class="buttons">
            <base-button-border-orange
              :styles="{ width: '120px', height: '30px' }"
              @click="openPrintPopup(resultGroup)"
              >検査印刷</base-button-border-orange
            >
          </div>
        </template>
      </examination-list-table>
    </div>
    <print-options-popup
      v-if="printFlg"
      :dataType="'examination'"
      :data="printExamination"
      :owner="getOwner(ownerId)"
      :patient="getPatient(patientId)"
      @close="() => (printFlg = false)"
    />
  </div>
</template>

<script>
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import ExaminationListTable from '@/components/parts/organisms/ExaminationListTable'
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import SearchArea from '@/components/parts/molecules/SearchArea'
import PrintOptionsPopup from '@/components/popups/PrintOptionsPopup'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Examinations',

  components: {
    BaseButtonBorderOrange,
    BaseSelectBox,
    ExaminationListTable,
    OwnerPatientDetail,
    SearchArea,
    PrintOptionsPopup
  },

  props: {
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      searchWord: '',
      startDate: '',
      endDate: '',
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      radioButtonValue: 1,
      radioButtonData: [
        { id: 1, eachValue: 1, labelName: 'すべて' },
        { id: 2, eachValue: 2, labelName: '血液検査のみ' },
        { id: 3, eachValue: 3, labelName: '血液検査以外' }
      ],
      printExamination: {},
      printFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getExaminationResultsByPatientId: 'examinationResults/getDataByPatientId',
      getTreatmentById: 'treatments/getDataById',
      getFormat: 'examinationFormats/getDataById',
      getTopicClass: 'examinationTopicClasses/getDataById',
      getTopic: 'examinationTopics/getDataById',
      getExaminationSpecies: 'examinationSpecies/getDataById',
      getSpecies: 'species/getDataById'
    }),
    results() {
      return this.getExaminationResultsByPatientId(this.patientId) || []
    },
    displayResults() {
      return this.results.map(result => {
        const format = this.getFormat(result.examinationFormatId)
        const treatment = this.getTreatmentById(result.treatmentId)
        const topicClass = this.getTopicClass(result.examinationTopicClassId)
        const topic = this.getTopic(result.examinationTopicId)
        const examinationSpecies = this.getExaminationSpecies(
          result.examinationSpeciesId
        )
        const speciesName = examinationSpecies
          ? this.getSpecies(examinationSpecies.speciesId).name || ''
          : ''
        const reference = examinationSpecies?.reference || ''
        const numFlg = topic.examinationType === '数値' && reference !== ''
        const min = numFlg ? reference.split(',')[0] : ''
        const max = numFlg ? reference.split(',')[1] : ''
        return {
          ...result,
          treatmentName: treatment.name,
          topicClassName: topicClass.name,
          topicName: topic.name,
          speciesName,
          resultUnit: topic.unit
            ? result.result + '（' + topic.unit + '）'
            : result.result,
          unit: topic.unit,
          bloodFlg: format.bloodFlg,
          reference: min === '' && max === '' ? reference : `${min} – ${max}`
        }
      })
    },
    groupedResults() {
      let displayData = []
      const resultGroups = this.groupResults(this.displayResults)
      Object.values(resultGroups).forEach(group => {
        const subGroups = _.groupBy(group, 'topicClassName')
        displayData.push({ data: Object.values(subGroups), showFlg: true })
      })
      return displayData
    },
    filteredResults() {
      const list =
        this.radioButtonValue === 2
          ? this.groupedResults.filter(
              resultGroup => resultGroup.data[0][0].bloodFlg === 1
            )
          : this.radioButtonValue === 3
          ? this.groupedResults.filter(
              resultGroup => resultGroup.data[0][0].bloodFlg === 0
            )
          : this.groupedResults
      return list
        .filter(resultGroup => {
          const date = resultGroup.data[0][0].date
          return this.startDate !== '' && this.endDate !== ''
            ? this.startDate <= date && date <= this.endDate
            : this.startDate !== ''
            ? this.startDate <= date
            : this.endDate !== ''
            ? date <= this.endDate
            : true
        })
        .filter(resultGroup => {
          for (let result of resultGroup.data.flat()) {
            if (
              result.treatmentName
                .toLowerCase()
                .indexOf(this.searchWord.toLowerCase().replace(/\s+/g, '')) !==
                -1 ||
              result.topicClassName
                .toLowerCase()
                .indexOf(this.searchWord.toLowerCase().replace(/\s+/g, '')) !==
                -1 ||
              result.topicName
                .toLowerCase()
                .indexOf(this.searchWord.toLowerCase().replace(/\s+/g, '')) !==
                -1 ||
              result.result
                .toLowerCase()
                .indexOf(this.searchWord.toLowerCase().replace(/\s+/g, '')) !==
                -1 ||
              result.unit
                .toLowerCase()
                .indexOf(this.searchWord.toLowerCase().replace(/\s+/g, '')) !==
                -1 ||
              result.speciesName
                .toLowerCase()
                .indexOf(this.searchWord.toLowerCase().replace(/\s+/g, '')) !==
                -1 ||
              result.comment
                .toLowerCase()
                .indexOf(this.searchWord.toLowerCase().replace(/\s+/g, '')) !==
                -1
            ) {
              return true
            }
          }
          return false
        })
        .sort((groupA, groupB) => {
          const a = groupA.data[0][0]
          const b = groupB.data[0][0]
          if (this.orderType === 0) {
            return a.date === b.date
              ? b.originalId - a.originalId
              : b.date < a.date
              ? -1
              : 1
          } else {
            return a.date === b.date
              ? a.originalId - b.originalId
              : a.date < b.date
              ? -1
              : 1
          }
        })
    }
  },

  methods: {
    groupResults(results) {
      let groups = {}
      results.forEach(r => {
        const index = `${r.medicalContentId},${r.examinationResultGroupId}`
        if (groups[index]) {
          groups[index].push(r)
        } else {
          groups[index] = [r]
        }
      })
      return groups
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    changeRadioButtonValue(id) {
      this.radioButtonValue = id
    },
    openPrintPopup(resultGroup) {
      this.printFlg = true
      this.printExamination = { examinationItems: resultGroup }
    }
  }
}
</script>

<style lang="scss" scoped>
.examinations {
  display: inline-block;
  min-width: 100%;
  > .owner-patient {
    margin-top: 40px;
  }
  > .search {
    margin-top: 40px;
  }
  > .search-order {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    > .order-setting {
      display: flex;
      align-items: center;
      > .label {
        margin-right: 10px;
      }
    }
  }
  > .list {
    min-width: 1175px;
    margin-top: 15px;
    ::v-deep .table-body {
      max-height: 630px;
    }
    .buttons {
      align-self: flex-end;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;
    }
  }
}
</style>
