<template>
  <div
    class="medical-payment-list-table-row"
    :class="{
      first: index === 0,
      even: index % 2 !== 0,
      update: needsMiddleCalculateOrEndMedicalPaymentUpdateFlg
    }"
    ref="target"
    @click="$emit('click-item', medicalPayment)"
  >
    <div class="upper">
      <div
        class="cell owner"
        v-if="!patientMedicalPaymentsFlg"
        :title="
          `${medicalPayment.ownerShowId}\n${medicalPayment.ownerFullName}`
        "
      >
        <div class="text owner-show-id">{{ medicalPayment.ownerShowId }}</div>
        <div class="text owner-full-name">
          {{ medicalPayment.ownerFullName }}
        </div>
      </div>
      <div
        class="cell patient"
        v-if="!patientMedicalPaymentsFlg"
        :title="`${medicalPayment.patientShowId}\n${patientNameSpecies}`"
      >
        <div class="text patient-show-id">
          {{ medicalPayment.patientShowId }}
        </div>
        <div class="text patient-name">{{ patientNameSpecies }}</div>
      </div>
      <div class="cell date" :style="dateStyles">{{ formatDate }}</div>
      <div
        class="cell disease-name"
        :title="medicalPayment.diseaseName"
        :style="diseaseNameStyles"
      >
        {{ medicalPayment.diseaseName }}
      </div>
      <div class="button-icon">
        <div class="button-icon-area">
          <base-button-trash
            class="button"
            v-if="lookOnlyFlg === 0 && deleteButtonShowFlg"
            :styles="{ width: '30px', height: '30px' }"
            @click.stop="$emit('open-delete-popup', medicalPayment)"
          />
          <div class="icon-area">
            <div class="icon" v-if="medicalPayment.startHospitalizationFlg">
              入院
            </div>
            <div class="icon" v-if="medicalPayment.inHospitalFlg">
              入院中
            </div>
            <div class="icon" v-if="medicalPayment.middleCalculateFlg">
              途中精算
            </div>
            <div class="icon" v-if="medicalPayment.endHospitalizationFlg">
              退院
            </div>
          </div>
          <div class="round-button-area">
            <base-button-round
              class="button"
              v-if="
                paymentType(medicalPayment) === '再会計' &&
                  !isInHospital(medicalPayment)
              "
              :styles="{ borderColor: '#de3d3d' }"
              :textStyles="{ color: '#de3d3d' }"
              @click.stop="$emit('go-to-payment-show-page', medicalPayment)"
              >再会計</base-button-round
            >
            <base-button-round
              class="button"
              v-if="
                paymentType(medicalPayment) === '未会計' &&
                  !isInHospital(medicalPayment)
              "
              :styles="{ borderColor: '#de3d3d' }"
              :textStyles="{ color: '#de3d3d' }"
              @click.stop="$emit('go-to-payment-new-page', medicalPayment)"
              >未会計</base-button-round
            >
            <base-button-round
              class="button"
              v-if="
                paymentType(medicalPayment) === '未収金' &&
                  !isInHospital(medicalPayment)
              "
              :styles="{ borderColor: '#de3d3d' }"
              :textStyles="{ color: '#de3d3d' }"
              @click.stop="$emit('go-to-payment-show-page', medicalPayment)"
              >未収金</base-button-round
            >
          </div>
        </div>
      </div>
    </div>
    <div class="under">
      <div class="item left">
        <data-table
          v-if="medicalPayment.medicalTreatmentItems.length > 0"
          v-bind="medicalTreatmentItemData"
          :items="displayTreatmentItems"
        />
      </div>
      <div v-if="!isInHospital(medicalPayment)" class="item right">
        <data-table v-bind="priceItemData" :items="displayPriceDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/parts/organisms/DataTable'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseButtonRound from '@/components/parts/atoms/BaseButtonRound'
import { makePaymentDetail } from '@/utils/price_calculation'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { needsMiddleCalculateOrEndMedicalPaymentUpdate } from '@/utils/hospitalization_utils'

const medicalTreatmentItemStyles = [
  { width: '38%' },
  { width: '14%' },
  { width: '10%' },
  { width: '10%' },
  { width: '14%' },
  { width: '14%' }
]

export default {
  name: 'MedicalPaymentListTableRow',

  components: {
    DataTable,
    BaseButtonTrash,
    BaseButtonRound
  },

  props: {
    medicalPayment: { type: Object },
    index: { type: Number },
    lastIndex: { type: Number, default: 0 },
    initialDisplayFlg: { type: Boolean },
    patientMedicalPaymentsFlg: { type: Boolean, default: false },
    deleteButtonShowFlg: { type: Boolean },
    patientHospitalizations: { type: Array }
  },

  data() {
    return {
      dateStyles: this.patientMedicalPaymentsFlg ? { width: '25%' } : {},
      diseaseNameStyles: this.patientMedicalPaymentsFlg ? { width: '75%' } : {},
      medicalTreatmentItemData: {
        keys: [
          'name',
          'unitPrice',
          'amount',
          'discountRate',
          'discountPrice',
          'subtotal'
        ],
        headers: [
          '診療項目内容',
          '単価（￥）',
          '数量',
          '割引（%）',
          '値引（￥）',
          '小計（￥）'
        ],
        headerCellStyles: medicalTreatmentItemStyles,
        bodyCellStyles: medicalTreatmentItemStyles.map((v, i) => {
          return { ...v, textAlign: i === 0 ? 'center' : v.textAlign }
        }),
        bodyMaxHeight: '120px',
        scrollFlg: true
      },
      priceItemData: {
        keys: [
          'discountRate',
          'discountPrice',
          'pledgeRate',
          'insurancePrice',
          'consumptionTax',
          'ownerBill'
        ],
        headers: [
          '全体割引（%）',
          '全体値引（￥）',
          '保険割引率（%）',
          '保険負担額（￥）',
          '消費税（￥）',
          '飼主請求額（￥）'
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getMedicalRecord: 'medicalRecords/getDataByOriginalId',
      getMedicalPaymentByOriginalId: 'medicalPayments/getDataByOriginalId',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId'
    }),
    patientNameSpecies() {
      return this.medicalPayment.speciesName
        ? `${this.medicalPayment.patientName} （${this.medicalPayment.speciesName}）`
        : `${this.medicalPayment.patientName}`
    },
    formatDate() {
      return moment(this.medicalPayment.date, 'YYYYMMDD').format(
        'YYYY年MM月DD日（dd）'
      )
    },
    displayTreatmentItems() {
      return this.medicalPayment.medicalTreatmentItems.map(v => {
        return {
          ...v,
          unitPrice: `￥${v.unitPrice.toLocaleString()}`,
          discountRate: `${v.discountRate.toLocaleString()} %`,
          discountPrice: `￥${v.discountPrice.toLocaleString()}`,
          subtotal: `￥${v.subtotal.toLocaleString()}`
        }
      })
    },
    displayPriceDetails() {
      return [
        {
          discountRate: this.medicalPayment.discountRate + ' %',
          discountPrice:
            '￥' + this.medicalPayment.discountPrice.toLocaleString(),
          pledgeRate: this.medicalPayment.pledgeRate + ' %',
          insurancePrice:
            '￥' + this.medicalPayment.insurancePrice.toLocaleString(),
          consumptionTax:
            '￥' + this.medicalPayment.consumptionTax.toLocaleString(),
          ownerBill: '￥' + this.medicalPayment.burdenAmount.toLocaleString()
        }
      ]
    },
    needsMiddleCalculateOrEndMedicalPaymentUpdateFlg() {
      if (this.patientMedicalPaymentsFlg) {
        //入院の診療明細を削除した時に、virtual-scrollerの影響で
        //削除した退院の診療明細でneedsMiddleCalculateOrEndMedicalPaymentUpdateFlgの処理が一度動くようです。
        //この時storeのデータは最新に更新されていて入退院のデータがない状況が発生するので、
        //その場合はfalseを返すようにしてます。
        return Boolean(
          needsMiddleCalculateOrEndMedicalPaymentUpdate(
            this.medicalPayment,
            this.patientHospitalizations,
            this.getMedicalPaymentByOriginalId
          )
        )
      } else {
        return false
      }
    }
  },

  mounted() {
    if (!this.patientMedicalPaymentsFlg) {
      const target = this.$refs.target
      let observer = new IntersectionObserver(() => {
        this.emitScrollBottom()
      })
      observer.observe(target)
    }
  },

  methods: {
    emitScrollBottom() {
      if (
        !this.initialDisplayFlg &&
        this.medicalPayment.number === this.lastIndex
      ) {
        this.$emit('scroll-bottom')
      }
    },
    isInHospital(medicalPayment) {
      return (
        (medicalPayment.startHospitalizationFlg === 1 ||
          medicalPayment.inHospitalFlg === 1) &&
        medicalPayment.middleCalculateFlg === 0 &&
        medicalPayment.endHospitalizationFlg === 0
      )
    },
    paymentType(medicalPayment) {
      const { type } = makePaymentDetail(
        medicalPayment,
        this.getMedicalPaymentHistory,
        this.getMedicalRecord,
        this.getPaymentsByMedicalPaymentId
      )
      return type
    }
  }
}
</script>

<style lang="scss" scoped>
$box-border-color: #{$light-grey};
.medical-payment-list-table-row {
  width: 100%;
  border-top: 1px solid #{$light-grey};
  &.first {
    border-top: none;
  }
  &.even {
    background-color: #{$white_f7};
  }
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &.update {
    background-color: #{$pale};
  }
  > .upper {
    min-height: 60px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    > .cell {
      box-sizing: border-box;
      padding: 0 10px;
      &.owner {
        width: 20%;
        overflow: hidden;
        > .text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      &.patient {
        width: 20%;
        overflow: hidden;
        > .text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      &.date {
        width: 16%;
      }
      &.disease-name {
        width: 44%;
      }
    }
    > .button-icon {
      min-width: 265px;
      height: 30px;
      > .button-icon-area {
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;
        > .icon-area {
          display: flex;
          margin-left: 15px;
          > .icon {
            margin-right: 15px;
            height: 30px;
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: bold;
            color: #{$pumpkin};
          }
        }
        > .round-button-area {
          width: 90px;
        }
      }
    }
  }
  > .under {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 20px 20px 20px;
    > .left {
      width: calc(55% - 10px);
    }
    > .right {
      width: calc(45% - 10px);
    }
  }
}
</style>
