<template>
  <div class="hospitalization-input-form">
    <div class="left">
      <div v-if="showStartFlg" class="start">
        <div v-if="showStartDateFlg" class="text" data-test="start-date">
          <span>入院開始 {{ formatDate(startDateTime) }}</span>
        </div>
        <div v-else class="checkbox">
          <base-check-box
            data-test="start-checkbox"
            :disabled="disabledStartFlg"
            :isChecked="medicalPayment.startHospitalizationFlg"
            :labelText="'入院開始'"
            :labelStyle="{ marginLeft: '3px' }"
            :numFlg="true"
            @input="$emit('input-start-hospitalization-flg', $event)"
          />
        </div>
      </div>
      <div v-if="showInFlg" class="in">
        <div class="checkbox">
          <base-check-box
            data-test="in-checkbox"
            :disabled="disabledInFlg"
            :isChecked="medicalPayment.inHospitalFlg"
            :labelText="'入院中'"
            :labelStyle="{ marginLeft: '3px' }"
            :numFlg="true"
            @input="$emit('input-in-hospital-flg', $event)"
          />
        </div>
        <div v-if="showStartDateFlg" class="text" data-test="in-date">
          <span>（入院開始 {{ formatDate(startDateTime) }}）</span>
        </div>
      </div>
    </div>
    <div class="middle">
      <div v-if="showMiddleCalculateFlg" class="middle-calculate">
        <div class="checkbox">
          <base-check-box
            data-test="middle-calculate-checkbox"
            :disabled="disabledMiddleCalculateFlg"
            :isChecked="medicalPayment.middleCalculateFlg"
            :labelText="'途中精算'"
            :labelStyle="{ marginLeft: '3px' }"
            :numFlg="true"
            @input="$emit('input-middle-calculate-flg', $event)"
          />
        </div>
      </div>
      <div
        v-if="
          medicalPayment.startHospitalizationFlg === 0 &&
            medicalPayment.endHospitalizationFlg === 1 &&
            middleCalculateDate
        "
        data-test="middle-calculate-date"
      >
        {{ middleCalculateDate }}
      </div>
    </div>
    <div class="right">
      <div v-if="showEndFlg" class="end">
        <div class="checkbox">
          <base-check-box
            data-test="end-checkbox"
            :disabled="disabledEndFlg"
            :isChecked="medicalPayment.endHospitalizationFlg"
            :labelText="'退院'"
            :labelStyle="{ marginLeft: '3px' }"
            :numFlg="true"
            @input="$emit('input-end-hospitalization-flg', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox.vue'
import moment from 'moment'
import {
  hasEnd,
  hasIn,
  hasSameStartEnd,
  isEnd,
  isHospitalization,
  isIn,
  isStart,
  isStartEnd
} from '@/utils/medical_payment_helper'
import { mapGetters } from 'vuex'

export default {
  name: 'HospitalizationInputForm',

  components: {
    BaseCheckBox
  },

  props: {
    initialMedicalPayment: { type: [Object, null], default: null },
    medicalPayment: { type: Object },
    medicalRecord: { type: Object },
    hospitalizationInfo: { type: Object }
  },

  computed: {
    ...mapGetters({
      paymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId'
    }),
    isNew() {
      return this.medicalPayment.id ? false : true
    },
    isOutpatient() {
      const iMP = this.initialMedicalPayment
      return iMP && !isHospitalization(iMP) ? true : false
    },
    isInpatient() {
      const iMP = this.initialMedicalPayment
      return iMP && isHospitalization(iMP) ? true : false
    },
    showStartFlg() {
      const { current } = this.hospitalizationInfo
      const mp = this.medicalPayment
      let canShow
      if (this.isNew || this.isOutpatient) {
        canShow = !current || (current && mp.endHospitalizationFlg === 1)
      } else if (this.isInpatient) {
        canShow = isStartEnd(mp) || isStart(mp) || isEnd(mp)
      }
      return Boolean(canShow)
    },
    disabledStartFlg() {
      const { current, next } = this.hospitalizationInfo
      let disabled
      if (this.isNew) {
        disabled = current || next
      } else if (this.isOutpatient) {
        disabled = current || next || this.hasValidPayment || this.isRedo
      } else if (this.isInpatient) {
        disabled = true
      }
      return Boolean(disabled)
    },
    showInFlg() {
      const { current } = this.hospitalizationInfo
      const mp = this.medicalPayment
      let canShow
      if (this.isNew || this.isOutpatient) {
        canShow = current && mp.endHospitalizationFlg === 0
      } else if (this.isInpatient) {
        canShow = isIn(mp)
      }
      return Boolean(canShow)
    },
    disabledInFlg() {
      const { current } = this.hospitalizationInfo
      let disabled
      if (this.isNew) {
        disabled = current && hasSameStartEnd(current)
      } else if (this.isOutpatient) {
        disabled =
          (current && hasSameStartEnd(current)) ||
          this.hasValidPayment ||
          this.isRedo
      } else if (this.isInpatient) {
        disabled = true
      }
      return Boolean(disabled)
    },
    showMiddleCalculateFlg() {
      return (
        isStartEnd(this.medicalPayment) ||
        isStart(this.medicalPayment) ||
        isIn(this.medicalPayment) ||
        (isEnd(this.medicalPayment) && !this.middleCalculateDate)
      )
    },
    disabledMiddleCalculateFlg() {
      const { current, same } = this.hospitalizationInfo
      return Boolean(
        this.medicalPayment.endHospitalizationFlg === 1 ||
          (current && current.endDate !== '') ||
          (this.initialMedicalPayment.endHospitalizationFlg === 0 &&
            same &&
            same.endDate !== '') ||
          (current && current.middleCalculateDate !== '') ||
          (this.initialMedicalPayment.middleCalculateFlg === 0 &&
            same &&
            same.middleCalculateDate !== '') ||
          this.hasValidPayment ||
          this.isRedo
      )
    },
    middleCalculateDate() {
      const { current, same } = this.hospitalizationInfo
      return current?.middleCalculateDate
        ? '途中精算 ' +
            moment(current.middleCalculateDate, 'YYYYMMDD').format(
              'YYYY年MM月DD日'
            )
        : same?.middleCalculateDate &&
          this.initialMedicalPayment.middleCalculateFlg === 0 //→途中精算の診療明細を退院に変えた時に途中精算日を表示しないようにする
        ? '途中精算 ' +
          moment(same.middleCalculateDate, 'YYYYMMDD').format('YYYY年MM月DD日')
        : ''
    },
    showEndFlg() {
      const { current } = this.hospitalizationInfo
      const mp = this.medicalPayment
      let canShow
      if (this.isNew || this.isOutpatient) {
        if (current) {
          canShow = mp.inHospitalFlg === 1 || mp.endHospitalizationFlg === 1
        } else {
          canShow = mp.startHospitalizationFlg === 1
        }
      } else if (this.isInpatient) {
        canShow = true
      }
      return Boolean(canShow)
    },
    disabledEndFlg() {
      const { current, next, same } = this.hospitalizationInfo
      let disabled
      const imp = this.initialMedicalPayment
      if (this.isNew || this.isOutpatient) {
        if (current) {
          disabled = current.endDateTime !== ''
        }
      } else if (this.isInpatient) {
        if (isStart(imp)) {
          disabled = hasIn(same) || hasEnd(same) || next
        }
        if (isIn(imp)) {
          disabled = hasEnd(same) || next
        }
        if (isStartEnd(imp) || isEnd(imp)) {
          disabled = next
        }
      }
      disabled =
        disabled ||
        this.isLessEnd ||
        this.hasValidPayment ||
        this.isRedo ||
        Boolean(this.medicalPayment.middleCalculateFlg)
      return Boolean(disabled)
    },
    showStartDateFlg() {
      const { current } = this.hospitalizationInfo
      const mp = this.medicalPayment
      let canShow
      if (this.isNew || this.isOutpatient) {
        canShow = current?.startDateTime
      } else if (this.isInpatient) {
        canShow = isStartEnd(mp) || isIn(mp) || isEnd(mp)
      }
      return Boolean(canShow)
    },
    startDateTime() {
      const { current, same } = this.hospitalizationInfo
      let startDateTime = ''
      if (this.isNew || this.isOutpatient) {
        startDateTime = current?.startDateTime
      } else if (this.isInpatient) {
        startDateTime = same?.startDateTime
      }
      return startDateTime
    },
    isLessEnd() {
      const { current, same } = this.hospitalizationInfo
      const endDateTime = this.medicalRecord.date + this.medicalRecord.endTime
      let isLess
      if (this.isNew || this.isOutpatient) {
        isLess =
          current &&
          current.inEndDateTime !== '' &&
          endDateTime < current.inEndDateTime
      } else if (this.isInpatient) {
        isLess = same.inEndDateTime !== '' && endDateTime < same.inEndDateTime
      }
      return Boolean(isLess)
    },
    hasValidPayment() {
      const payments = this.paymentsByMedicalPaymentId(this.medicalPayment.id)
      const hasValidPayment = Array.isArray(payments)
        ? payments.some(
            p => p.cancelDateTime === '00000000000000' && p.delFlg === 0
          )
        : false
      return hasValidPayment
    },
    isRedo() {
      return this.medicalPayment.redoFlg === 1
    }
  },

  methods: {
    formatDate(YYYYMMDDHHmm) {
      return YYYYMMDDHHmm
        ? moment(YYYYMMDDHHmm, 'YYYYMMDDHHmm').format('YYYY年MM月DD日')
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.hospitalization-input-form {
  display: flex;
  align-items: center;
  gap: 0 13px;
  > .left {
    display: flex;
    align-items: center;
    > .start {
      display: flex;
      align-items: center;
      > .text {
        font-size: 13px;
      }
    }
    > .in {
      display: flex;
      align-items: center;
      > .text {
        font-size: 13px;
      }
    }
  }
  > .middle {
    display: flex;
    align-items: center;
  }
  > .right {
    display: flex;
    align-items: center;
  }
}
</style>
