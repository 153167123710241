<template>
  <div class="estimate-input-form">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="white">
        <div class="date-staff">
          <div class="item date" data-test="date" v-if="estimate.id">
            <div class="label">見積日</div>
            <div class="value">{{ formatDate(estimate.date) }}</div>
          </div>
          <div class="item staff">
            <div class="label">担当者</div>
            <div class="value">
              <base-select-box
                :selectData="staffsSelectData"
                :styles="{ width: '200px' }"
                v-model.number="estimate.staffId"
              />
            </div>
          </div>
        </div>
        <div class="diseases">
          <disease-select-form
            :diseaseOriginalId="estimate.disease1OriginalId"
            :label="'診断1'"
            v-model="estimate.disease1OriginalId"
          />
          <disease-select-form
            :diseaseOriginalId="estimate.disease2OriginalId"
            :label="'診断2'"
            v-model="estimate.disease2OriginalId"
          />
        </div>
        <div class="insurance">
          <insurance-apply-form
            :patient="patient"
            :anicomPatient="anicomPatient"
            :ipetPatient="ipetPatient"
            :estimate="estimate"
            :anicomCIdCheck="anicomCIdCheck"
            :ipetCheck="ipetCheck"
            @input="inputApplyFlg"
            @click-no-apply-reason="noApplyReasonPopupFlg = true"
            @click-insurance-setting="insurancePopupFlg = true"
          />
        </div>
        <div class="surgery">
          <div class="item">
            <base-check-box
              :isChecked="estimate.surgeryFlg"
              :labelText="'手術'"
              :labelStyle="{ marginLeft: '5px' }"
              :numFlg="true"
              v-model="estimate.surgeryFlg"
            />
          </div>
        </div>
        <div class="treatment-items">
          <treatment-items-set-table
            v-bind="treatmentItemData"
            :items="displayTreatmentItems"
            :sortItems="estimateTreatmentItems"
            @order="items => $emit('order', items)"
            @click-trash="item => $emit('click-trash', item)"
            @click-plus="selectPopupFlg = true"
            @click-item="clickItem"
          />
        </div>
      </div>
      <div class="pale">
        <div class="estimate-calculation">
          <price-input-table
            :priceItem="estimate"
            :treatmentItems="estimateTreatmentItems"
            :priceDetail="priceDetail"
          />
        </div>
        <div class="bill">
          <div class="item insurance">
            <div class="label">保険請求額</div>
            <div class="value">￥{{ priceDetail.insurancePrice }}</div>
          </div>
          <div class="item owner">
            <div class="label">飼主請求額</div>
            <div class="value">￥{{ priceDetail.ownerBill }}</div>
          </div>
        </div>
        <div class="memo">
          <div class="label-button">
            <div class="label">備考</div>
            <base-button-border-orange
              class="button"
              :styles="{ width: '130px' }"
              @click="templatesPopupFlg = true"
              >備考テンプレート</base-button-border-orange
            >
          </div>
          <div
            class="warning"
            data-test="warning"
            v-show="
              estimate.applyFlg &&
                (estimate.anicomCIdCheckId !== 0 || estimate.ipetCheckId !== 0)
            "
          >
            保険請求額、飼主請求額は変更になる可能性があります。
          </div>
          <div class="value">
            <validation-provider
              :rules="{ notSurrogatePair: true }"
              v-slot="{ errors }"
            >
              <base-multiple-lines-text-box
                :styles="{ width: '100%' }"
                v-model="estimate.memo"
              />
              <div class="error" data-test="err">{{ errors[0] }}</div>
            </validation-provider>
          </div>
        </div>
        <div class="buttons">
          <base-button-border-orange
            class="button"
            :styles="{ width: '200px', fontSize: '14px' }"
            @click="$emit('cancel')"
          >
            戻る</base-button-border-orange
          >
          <base-button-small-orange
            v-if="lookOnlyFlg === 0"
            class="button"
            data-test="register"
            :disabled="invalid || waitFlg || isCheckingApply"
            :styles="{ width: '200px', fontSize: '14px' }"
            @click="$emit('decision')"
          >
            登録
          </base-button-small-orange>
        </div>
      </div>
      <insurance-popup
        v-if="insurancePopupFlg"
        :patient="patient"
        :anicomPatient="anicomPatient"
        :ipetPatient="ipetPatient"
        @close="insurancePopupFlg = false"
        @updated="updatedInsurance"
      />
      <treatment-items-select-popup
        v-if="selectPopupFlg"
        :patient="patient"
        :insuranceType="estimate.insuranceType"
        @cancel="selectPopupFlg = false"
        @decision="addTreatmentItems"
      />
      <treatment-items-input-popup
        v-if="inputPopupFlg"
        :treatmentItems="[targetTreatmentItem]"
        :insuranceType="estimate.insuranceType"
        @cancel="inputPopupFlg = false"
        @decision="changeTreatmentItem"
      />
      <estimate-templates-popup
        v-if="templatesPopupFlg"
        @close="templatesPopupFlg = false"
        @select="setTemplate"
      />
    </validation-observer>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import DiseaseSelectForm from '@/components/parts/molecules/DiseaseSelectForm'
import InsuranceApplyForm from '@/components/parts/organisms/InsuranceApplyForm'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox.vue'
import TreatmentItemsSetTable from '@/components/parts/organisms/TreatmentItemsSetTable'
import PriceInputTable from '@/components/parts/organisms/PriceInputTable'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import InsurancePopup from '@/components/popups/InsurancePopup'
import TreatmentItemsSelectPopup from '@/components/popups/TreatmentItemsSelectPopup'
import TreatmentItemsInputPopup from '@/components/popups/TreatmentItemsInputPopup'
import EstimateTemplatesPopup from '@/components/popups/EstimateTemplatesPopup'
import { ValidationObserver } from 'vee-validate'
import { ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  makePriceDetail,
  calculateTreatmentItemSubtotal
} from '@/utils/price_calculation'

const treatmentItemStyles = [
  { width: '20%', textAlign: 'left', padding: '0 10px' },
  { width: '5%' },
  { width: '15%' },
  { width: '15%' },
  { width: '15%' },
  { width: '15%' },
  { width: '15%' }
].map(v => ({ ...v, fontSize: '11px' }))

export default {
  name: 'EstimateInputForm',

  components: {
    BaseSelectBox,
    DiseaseSelectForm,
    InsuranceApplyForm,
    BaseCheckBox,
    TreatmentItemsSetTable,
    PriceInputTable,
    BaseButtonBorderOrange,
    BaseButtonSmallOrange,
    BaseMultipleLinesTextBox,
    InsurancePopup,
    TreatmentItemsSelectPopup,
    TreatmentItemsInputPopup,
    EstimateTemplatesPopup,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    patient: { type: Object, required: true },
    anicomPatient: { type: Object, default: null },
    ipetPatient: { type: Object, default: null },
    estimate: { type: Object, required: true },
    estimateTreatmentItems: { type: Array },
    anicomCIdCheck: { type: Object, default: null },
    ipetCheck: { type: Object, default: null },
    lookOnlyFlg: { type: Number },
    waitFlg: { type: Boolean },
    isCheckingApply: { type: Boolean, default: false }
  },

  data() {
    return {
      treatmentItemData: {
        keys: [
          'name',
          'insuranceFlg',
          'unitPrice',
          'amount',
          'discountRate',
          'discountPrice',
          'subtotal'
        ],
        headers: [
          '診療項目内容',
          '保険',
          '単価（￥）',
          '数量',
          '割引（%）',
          '値引（￥）',
          '小計（￥）'
        ],
        headerCellStyles: treatmentItemStyles,
        bodyCellStyles: treatmentItemStyles.map((v, i) => {
          return { ...v, textAlign: i === 1 ? 'center' : v.textAlign }
        }),
        bodyMaxHeight: '180px',
        scrollFlg: true,
        stripeFlg: true
      },
      insurancePopupFlg: false,
      selectPopupFlg: false,
      inputPopupFlg: false,
      templatesPopupFlg: false,
      targetTreatmentItem: {}
    }
  },

  computed: {
    ...mapGetters({
      staffsSelectData: 'staffs/selectDataZero',
      treatmentByOriginalId: 'treatments/getDataByOriginalId',
      medicineByOriginalId: 'medicines/getDataByOriginalId'
    }),
    priceDetail() {
      return makePriceDetail(
        this.estimate,
        this.estimate.surgeryFlg, //← 0 or 1
        1,
        this.estimateTreatmentItems
      )
    },
    displayTreatmentItems() {
      return this.estimateTreatmentItems.map(item => {
        const base =
          this.treatmentByOriginalId(item.treatmentOriginalId) ||
          this.medicineByOriginalId(item.medicineOriginalId)
        const name = base?.name || ''
        const subtotal = calculateTreatmentItemSubtotal(
          this.estimate.insuranceType,
          item
        )
        return {
          ...item,
          name,
          insuranceFlg: item.insuranceFlg === 1 ? '◯' : '✕',
          unitPrice: `￥${item.unitPrice.toLocaleString()}`,
          discountRate: `${item.discountRate.toLocaleString()} %`,
          discountPrice: `￥${item.discountPrice.toLocaleString()}`,
          subtotal: `￥${subtotal.toLocaleString()}`
        }
      })
    }
  },

  methods: {
    formatDate(date) {
      return moment(date, 'YYYYMMDD').format('YYYY年MM月DD日（dd）')
    },
    inputApplyFlg(applyFlg) {
      this.$emit('input-apply-flg', applyFlg)
    },
    updatedInsurance() {
      this.$emit('updated-insurance')
      this.insurancePopupFlg = false
    },
    clickItem(treatmentItem) {
      this.targetTreatmentItem = this.estimateTreatmentItems.find(
        v => v.key === treatmentItem.key
      )
      this.inputPopupFlg = true
    },
    addTreatmentItems(treatmentItems) {
      this.$emit('add-items', treatmentItems)
    },
    changeTreatmentItem(treatmentItems) {
      this.$emit('change-item', treatmentItems[0])
      this.inputPopupFlg = false
    },
    setTemplate(content) {
      if (content.length > 0) {
        this.estimate.memo =
          this.estimate.memo.length > 0
            ? this.estimate.memo + '\n' + content
            : content
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$light-grey};

.estimate-input-form {
  box-sizing: border-box;
  border: solid 1px $border-color;
  > .validation-observer {
    > .white {
      padding: 20px 30px 20px 30px;
      background-color: #{$white};
      > .date-staff {
        display: flex;
        > .item {
          display: flex;
          align-items: center;
          > .label {
            width: 55px;
            margin-left: 10px;
            font-size: 13px;
          }
          > .value {
            margin-right: 30px;
            font-size: 16px;
          }
        }
      }
      > .diseases {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 110px;
        margin-top: 15px;
        background-color: #{$gray_f8};
        padding: 0 10px;
      }
      > .surgery {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        padding: 0 10px;
        font-size: 13px;
        background-color: #{$gray_f8};
      }
      > .treatment-items {
        margin-top: 15px;
      }
    }
    > .pale {
      padding: 15px 30px 30px 30px;
      background-color: rgba(252, 225, 204, 0.25);
      > .bill {
        box-sizing: border-box;
        margin-top: 20px;
        display: flex;
        height: 40px;
        border: 1px solid #{$pumpkin};
        background-color: #{$white};
        > .item {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          &.owner {
            color: #{$pumpkin};
          }
          > .label {
            font-size: 13px;
            font-weight: bold;
          }
          > .value {
            font-size: 15px;
          }
        }
        > .item ~ .item {
          border-left: 1px solid #{$pumpkin};
        }
      }
      > .buttons {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        > .button {
          margin: 0 20px;
        }
        ::v-deep .base-button-border-orange,
        ::v-deep .base-button-small-orange {
          > button span {
            height: auto;
            line-height: 20px;
            font-size: 13px;
            display: inline;
            vertical-align: baseline;
          }
        }
      }
      > .memo {
        margin-top: 15px;
        > .label-button {
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 10px;
          > .label {
            font-size: 13px;
            font-weight: bold;
          }
          > .button {
            margin-right: 2px;
          }
        }
        > .warning {
          font-size: 15px;
        }
        > .value {
          @include validate-message();
        }
      }
    }
  }
}
</style>
