<template>
  <div class="medical-payment-list-table">
    <div class="table-header">
      <div class="cell owner" v-if="!patientMedicalPaymentsFlg">
        <div>飼主ID</div>
        <div>飼主名</div>
      </div>
      <div class="cell patient" v-if="!patientMedicalPaymentsFlg">
        <div>患者ID</div>
        <div>患者名</div>
      </div>
      <div class="cell date" :style="cellDateStyles">診療日</div>
      <div class="cell disease-name" :style="cellDiseaseNameStyles">診断名</div>
      <div class="cell button-area" />
    </div>
    <dynamic-scroller
      class="table-body"
      :items="medicalPayments"
      :min-item-size="minItemSize"
      :buffer="buffer"
      ref="scroller"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :data-index="index"
          :size-dependencies="[item.medicalTreatmentItems]"
        >
          <medical-payment-list-table-row
            :medicalPayment="item"
            :index="index"
            :lastIndex="lastIndex"
            :initialDisplayFlg="initialDisplayFlg"
            :patientMedicalPaymentsFlg="patientMedicalPaymentsFlg"
            :deleteButtonShowFlg="deleteButtonShowFlg"
            :patientHospitalizations="patientHospitalizations"
            @click-item="medicalPayment => $emit('click-item', medicalPayment)"
            @go-to-payment-show-page="
              medicalPayment => $emit('go-to-payment-show-page', medicalPayment)
            "
            @go-to-payment-new-page="
              medicalPayment => $emit('go-to-payment-new-page', medicalPayment)
            "
            @open-delete-popup="
              medicalPayment => $emit('open-delete-popup', medicalPayment)
            "
            @scroll-bottom="$emit('scroll-bottom')"
          >
          </medical-payment-list-table-row>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
  </div>
</template>

<script>
import MedicalPaymentListTableRow from '@/components/parts/molecules/MedicalPaymentListTableRow'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { mapGetters } from 'vuex'

const medicalTreatmentItemStyles = [
  { width: '38%' },
  { width: '14%' },
  { width: '10%' },
  { width: '10%' },
  { width: '14%' },
  { width: '14%' }
]

export default {
  name: 'MedicalPaymentListTable',

  components: {
    MedicalPaymentListTableRow,
    DynamicScroller,
    DynamicScrollerItem
  },

  props: {
    medicalPayments: { type: Array },
    lastIndex: { type: Number, default: 0 },
    initialDisplayFlg: { type: Boolean },
    patientMedicalPaymentsFlg: { type: Boolean, default: false },
    patientId: { type: Number, default: 0 },
    deleteButtonShowFlg: { type: Boolean }
  },

  data() {
    return {
      cellDateStyles: this.patientMedicalPaymentsFlg ? { width: '25%' } : {},
      cellDiseaseNameStyles: this.patientMedicalPaymentsFlg
        ? { width: '75%' }
        : {},
      medicalTreatmentItemData: {
        keys: [
          'name',
          'unitPrice',
          'amount',
          'discountRate',
          'discountPrice',
          'subtotal'
        ],
        headers: [
          '診療項目内容',
          '単価（￥）',
          '数量',
          '割引（%）',
          '値引（￥）',
          '小計（￥）'
        ],
        headerCellStyles: medicalTreatmentItemStyles,
        bodyCellStyles: medicalTreatmentItemStyles.map((v, i) => {
          return { ...v, textAlign: i === 0 ? 'center' : v.textAlign }
        }),
        bodyMaxHeight: '120px',
        scrollFlg: true
      },
      priceItemData: {
        keys: [
          'discountRate',
          'discountPrice',
          'pledgeRate',
          'insurancePrice',
          'consumptionTax',
          'ownerBill'
        ],
        headers: [
          '全体割引（%）',
          '全体値引（￥）',
          '保険割引率（%）',
          '保険負担額（￥）',
          '消費税（￥）',
          '飼主請求額（￥）'
        ]
      },
      minItemSize: 164,
      buffer: 100
    }
  },

  computed: {
    ...mapGetters({
      hospitalizationsByPatientId: 'hospitalizations/getDataByPatientId'
    }),
    patientHospitalizations() {
      return this.hospitalizationsByPatientId(this.patientId) || []
    }
  }
}
</script>

<style lang="scss" scoped>
$box-border-color: #{$light-grey};
.medical-payment-list-table {
  width: 100%;
  font-size: 15px;
  > .table-header {
    box-sizing: border-box;
    display: flex;
    height: 54px;
    border: 1px solid #{$light-grey};
    background-color: #{$pale};
    padding-right: 17px; //table-body内の値と縦の位置を揃えるため、スクロールバーのwidth分スペースを空ける
    > .cell {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      &.owner {
        width: 20%;
      }
      &.patient {
        width: 20%;
      }
      &.date {
        width: 16%;
      }
      &.disease-name {
        width: 44%;
      }
      &.button-area {
        min-width: 265px;
      }
    }
    > .cell ~ .cell {
      border-left: 1px solid #{$skin};
    }
  }
  > .table-body {
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    border: solid #{$light-grey};
    border-width: 0px 1px 1px 1px;
  }
}
</style>
